::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}
.att-scroller::-webkit-scrollbar {
  background-color: transparent;
}
::-webkit-scrollbar-button {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.jodit-wysiwyg {
  word-break: break-all;
}
.jodit-wysiwyg p {
  margin: 0;
}
.jodit-status-bar a.jodit-status-bar-link {
  display: none;
}
.clamped-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colsBorder::after {
  display: block;
  content: "";
  background: rgb(214, 214, 214);
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: -21px;
  pointer-events: none;
}
.jodit-add-new-line.jodit-add-new-line_after {
  display: none !important;
}

.disable_bar {
  overflow-y: auto;
}

.disable_bar::-webkit-scrollbar {
  display: none;
}

.disable_bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ntf-badge > span,
.todoBadge > span {
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 0;
  pointer-events: none;
}

.numberInput input[type="number"]::-webkit-inner-spin-button,
.numberInput input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput input[type="number"] {
  -moz-appearance: textfield;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #5cb85c;
}

.layout:has(.kanban) {
  background-color: #fff !important;
  height: auto !important;
  overflow: visible !important;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media print {
  .hide-on-print {
    display: none !important;
  }
  .hide-on-print-text {
    color: transparent !important;
  }
}
