/* Sidebar menu start */
.sidebar_menu {
  height: calc(100vh - 134px);
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar_menu::-webkit-scrollbar {
  display: none;
}

.drop-down-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}

.drop-down-menu > div,
.sidebar_menu > a {
  display: grid;
  grid-template-columns: 20px auto;
  height: 50px;
  gap: 20px;
  align-items: center;
}
.dd-items > a {
  display: grid;
  grid-template-columns: 8px auto;
  gap: 20px;
  align-items: center;
}

.sidebar_menu a {
  padding: 10px 20px;
  text-decoration: none;
  color: #555;
  font-weight: 500;
}
.drop-down-menu span {
  display: flex;
  align-items: center;
  color: #555;
  font-weight: 500;
}
.sidebar_menu svg:first-of-type {
  transition: 0.3s;
  font-size: 25px;
  color: #9c9c9c;
}
.drop-down-menu svg:last-child {
  transition: 0.3s;
  font-size: 20px;
  color: #9c9c9c;
}
.active-dropdown svg:last-child {
  transform: rotate(90deg);
}
.dd-items {
  padding-left: 40px;
  transition: 0.4s ease;
  max-height: 500px;
  overflow: hidden;
}
.dd-items.false {
  max-height: 0;
  opacity: 0;
}
.dd-items a:before {
  content: "";
  height: 7px;
  width: 7px;
  background-color: transparent;
  border: 2px solid #9c9c9c;
  border-radius: 50%;
}
.sidebar_menu > a,
.drop-down-menu {
  border-left: 5px solid transparent;
}
.sidebar_menu > a.active,
.sidebar_menu > a:hover,
.drop-down-menu:hover,
.drop-down-menu.true {
  background-color: #405189;
}
.sidebar_menu > a.active span,
.sidebar_menu > a:hover span,
.drop-down-menu:hover span,
.drop-down-menu.true span {
  color: #fff;
}

.sidebar_menu > a.active svg:first-of-type,
.sidebar_menu > a:hover svg:first-of-type {
  color: #fff;
}

.drop-down-menu.true span,
.drop-down-menu:hover span,
.drop-down-menu.true svg,
.drop-down-menu:hover svg {
  color: #fff;
}
.sidebar_menu > a.active,
.sidebar_menu > a:hover {
  color: #fff;
}
.drop-down-menu.true svg:last-child {
  transform: rotate(90deg);
}
.dd-items > a:hover::before,
.dd-items > a.active::before {
  background-color: #405189;
  border-color: #405189;
}
.dd-items > a:hover,
.dd-items > a.active {
  color: #405189;
}
