.login-container {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
}
/* Login left section */
.login-left-section {
  flex: 1;
  overflow-y: hidden;
}
.login-slider-inner {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
p.login-slider-title-1 {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
p.login-slider-title-2 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  padding-top: 10px;
}

/* Login right section */
.login-right-section {
  flex: 1;
  overflow-y: auto;
}
.login-right-inner {
  max-width: 460px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.login-right-inner .logo {
  width: 300px;
}
.login-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: -5px;
  padding-bottom: 25px;
}
.login-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.login-form-single-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.pass-hide-btn {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.login-form-btn {
  background-color: #27a9e3;
  color: #fff;
  font-size: 18px !important;
  cursor: pointer;
  transition: 0.3s;
}
.login-form-btn:hover {
  background-color: #64c0ea;
}
.login-form-remember-me {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
.login-form-remember-me > div {
  display: flex;
  gap: 7px;
}
.login-form-remember-me {
  color: #1f1f1f;
}
.login-form-remember-me label {
  cursor: pointer;
}
.login-form-remember-me a {
  color: #3734a9;
}
.login-footer {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
}
.login-footer p:first-of-type a {
  text-decoration: underline;
  color: #233f86;
}
.login-footer p:last-child a {
  color: #233f86;
}

/* Responsive 
  -------------------*/
@media (max-width: 700px) {
  .login-container {
    background: none;
    padding: 20px;
  }
  .login-right-inner .logo {
    display: block;
    margin: 0 auto;
  }
  .login-left-section {
    display: none;
  }
}
